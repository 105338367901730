import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../AuthProvider";
import TextInput from "../components/TextInput";
//import TextBox from "../components/TextBox";
import SaveFileModal from "../components/SaveFileModal";
import NewChatWarningDialog from "../components/NewChatWarningDialog";
import StyledButton from "../components/StyledButton";
import { createPowerPoint, getSuggestedPptxFilename } from "../api/apiCalls";
import AdaptiveChatContainer from "../components/AdaptiveChatContainer";
import CustomChatInterface from "../components/CustomChatInterface";
import { handleTaskRequest } from "../utils/taskUtils";

const WriteTab = ({
  llmChoice,
  handleLlmChoiceChange,
  userSelectedLLM,
  handleTaskChoiceChange,
  taskChoice,
  languageChoice,
  handleLanguageChoiceChange,
  summaryStyleChoice,
  handleSummaryStyleChoiceChange,
  isGenerating,
  scratchPadText,
  setScratchPadText,
  draftHistory,
  sourceText,
  setSourceText,
  handleFetchText,
  fetchError,
  isFetching,
  onFetchText,
  pressReleaseLanguage,
  setPressReleaseLanguage,
  currentTask,
  brainstormHistory,
  chatHistory,
  sessionData,
  setSessionData,
  updateTokenData,
  setCurrentTask,
  setIsNewChatSession,
  setBrainstormHistory,
  setChatHistory,
  setNonChatDraftHistory,
  setGeneratedText,
  setTokenInfo,
  isNewChatSession,
  exchangeRate,
  setUsageTask,
  updateTaskUsage,
  messages,
  setMessages,
  isTyping,
  setIsTyping,
  savePptxModalOpen,
  setSavePptxModalOpen,
  pptxFilename,
  setPptxFilename,
  isPptxGenerating,
  setIsPptxGenerating,
}) => {
  const { user } = useAuth();

  // New state for save chat modal
  const [saveChatModalOpen, setSaveChatModalOpen] = useState(false);
  const [showNewChatWarning, setShowNewChatWarning] = useState(false);
  const [chatFilename, setChatFilename] = useState("");
  const [setPendingNewChat] = useState(false);
  const [suggestedPptxFilename, setSuggestedPptxFilename] = useState("");

  const taskPlaceholderMapping = {
    Freeform:
      "For general writing tasks. Type your instructions in chatbox below. Specify genre/format (e.g., speech, essay, social media post, poem), topic, audience, length. Indicate key points and provide context (data, reference texts, etc.). Ask for revisions as needed.",
    Chat: "For freewheeling discussion on any topic. Develop ideas and generate drafts through conversation.",
    Brainstorm: "Enter a topic to start a structured brainstorming session.",
    Summary:
      "Enter text in chatbox below to summarize or URLs (one per line). Choose a summary style and press ↵ to summarize. Text from URLs will be fetched automatically.",
    Translation:
      "Enter text in chatbox below, choose target language, press ↵ to translate.",
    Press_Release:
      "Enter instructions (e.g., topic, context, key points) in chatbox below to generate draft press release for your organization.\n\nYou may enter a URL (followed by your instructions if needed). Text will be fetched automatically and draft generated. Ask for revisions as needed.",
    Press_Statement:
      "Enter instructions (e.g., topic, context, key points) in chatbox below to generate draft press statement for your organization. \n\nYou may enter a URL (followed by your instructions if needed). Text will be fetched automatically and draft generated. Ask for revisions as needed.",
    House_Resolution:
      "Enter instructions (e.g., topic, context, key points) in chatbox below to generate draft House Resolution for your organization. Ask for revisions as needed.",
    House_Bill:
      "Enter instructions (e.g., topic, context, key points) in chatbox below to generate draft House Bill for your organization. Ask for revisions as needed.",
    Explanatory_Note:
      "Enter instructions (e.g., topic, context, key points) in chatbox below to generate draft Explanatory Note for your organization. Ask for revisions as needed.",
    Makabayan_Press_Statement:
      "Enter instructions (e.g., topic, context, key points) in chatbox below to generate draft Makabayan press statement.\n\nYou may enter a URL (followed by your instructions if needed). Text will be fetched automatically and draft generated. Ask for revisions as needed.",
    "Q_&_A_Explainer":
      "Enter text (article, reference material, etc.) in chatbox below to generate a Q & A Explainer.",
  };

  const chatPlaceholder =
    taskPlaceholderMapping[taskChoice.replace(/\s+/g, "_")] ||
    "Enter instructions here.";

  useEffect(() => {
    if (!taskChoice) {
      const defaultTask =
        user?.org_type === "NDMO" || user?.org_type === "Partylist"
          ? "Press Release"
          : "Freeform";
      handleTaskChoiceChange({ target: { value: defaultTask } });
    }
  }, [
    user?.org_type,
    handleTaskChoiceChange,
    taskChoice,
    userSelectedLLM,
    llmChoice,
  ]);

  const taskOptions = {
    NDMO: [
      "Freeform",
      "Chat",
      "Brainstorm",
      "Summary",
      "Q & A Explainer",
      "Translation",
      "Press Release",
      "Press Statement",
    ],
    Others: [
      "Freeform",
      "Chat",
      "Brainstorm",
      "Summary",
      "Q & A Explainer",
      "Translation",
    ],
    Partylist: [
      "Freeform",
      "Chat",
      "Brainstorm",
      "Summary",
      "Q & A Explainer",
      "Translation",
      "Press Release",
      "Press Statement",
      "House Resolution",
      "House Bill",
      "Explanatory Note",
      "Makabayan Press Statement",
    ],
  };

  const displayedTasks = user?.org_type
    ? taskOptions[user.org_type] || taskOptions["Others"]
    : taskOptions["Others"];

  // Effect to set default filename when save chat modal opens
  useEffect(() => {
    if (saveChatModalOpen) {
      const generateDefaultFilename = () => {
        const timestamp = new Date()
          .toISOString()
          .replace(/[:]/g, "-")
          .split(".")[0];
        return `chat-export_${timestamp}`;
      };
      setChatFilename(generateDefaultFilename());
    }
  }, [saveChatModalOpen]);

  // Update suggested filename when opening modal
  const handleOpenSavePptxModal = async () => {
    try {
      const suggested = await getSuggestedPptxFilename(scratchPadText);
      // Strip any existing .pptx extension as the modal will add it
      const cleanFilename = suggested.replace(/\.pptx$/i, "");
      console.log("Setting filename to:", cleanFilename); // Debug
      setSuggestedPptxFilename(cleanFilename);
      setPptxFilename(cleanFilename); // Set the actual filename
      setSavePptxModalOpen(true);
    } catch (error) {
      console.error("Error getting filename suggestion:", error);
      setSuggestedPptxFilename("presentation");
      setPptxFilename("presentation");
      setSavePptxModalOpen(true);
    }
  };

  const handleSaveAsPPTX = async () => {
    setIsPptxGenerating(true);
    try {
      setSavePptxModalOpen(false);

      // Get the blob data
      const blobData = await createPowerPoint(scratchPadText);

      // Create a blob with the correct type
      const blob = new Blob([blobData], {
        type: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      });

      // Create and trigger download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      // Use the filename from state (already has .pptx extension handled in the UI)
      a.download = (pptxFilename || "presentation") + ".pptx";
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);

      setPptxFilename("");
    } catch (error) {
      console.error("Error creating PowerPoint:", error);
      // Add user notification here if desired
    } finally {
      setIsPptxGenerating(false);
    }
  };

  const handleSendMessage = async (userMessage) => {
    if (!userMessage.trim()) return;
  
    let modifiedPrompt = userMessage;
    const pressReleaseTasks = [
      "Press Release",
      "Press Statement",
      "Makabayan Press Statement",
    ];
  
    // Check if the selected task is one of the press release types
    if (pressReleaseTasks.includes(taskChoice)) {
      // Handle different language selections
      if (pressReleaseLanguage === "English & Filipino") {
        // *** NEW: Specific handling for "Press Statement" ***
        if (taskChoice === "Press Statement") {
          modifiedPrompt = `${userMessage}\n\nIn English with impactful passages in Filipino.`;
        } else {
          // Original handling for other press release types with Eng/Fil
          modifiedPrompt = `${userMessage}\n\nInclude quotes in English and Filipino.`;
        }
      } else if (pressReleaseLanguage === "Filipino") {
        // Original handling for Filipino remains the same
        modifiedPrompt = `${userMessage}\n\nWrite statement in Filipino.`;
      }
      // If pressReleaseLanguage is just "English" (or something else),
      // the prompt remains unmodified by this block, which seems to be the original behavior.
    }

    let usageTaskName = taskChoice;
    if (taskChoice === "Summary") {
      usageTaskName += `-${summaryStyleChoice}`;
    } else if (taskChoice === "Translation") {
      usageTaskName += `-${languageChoice}`;
    }
    setUsageTask(usageTaskName);
    updateTaskUsage(usageTaskName);

    setMessages((prev) => [
      ...prev,
      {
        message: userMessage,
        sender: "You",
        sentTime: new Date().toLocaleString(),
      },
    ]);

    setIsTyping(true);

    let chat_history = [];
    if (!(taskChoice === "Summary" || taskChoice === "Translation")) {
      chat_history = messages.map((msg) => ({
        role: msg.sender === "You" ? "user" : "assistant",
        content: msg.message,
      }));
      chat_history.push({ role: "user", content: userMessage });
    }

    const taskStateValues = {
      currentTask,
      prompt: modifiedPrompt,
      brainstormHistory,
      chatHistory,
      sessionData,
      setSessionData,
      updateTokenData,
      summaryStyleChoice,
    };

    const setTaskStates = {
      setCurrentTask,
      setIsNewChatSession,
      setBrainstormHistory,
      setChatHistory,
      setNonChatDraftHistory,
      setGeneratedText,
      setTokenInfo,
      isNewChatSession,
    };

    try {
      const response = await handleTaskRequest(
        taskChoice,
        llmChoice,
        languageChoice,
        summaryStyleChoice,
        setTaskStates,
        taskStateValues,
        exchangeRate,
        chat_history
      );

      const modelMessage = {
        message: response?.response || "No response from the model",
        sender: "MakGPT",
        sentTime: new Date().toLocaleString(),
      };
      setMessages((prev) => [...prev, modelMessage]);
    } catch (err) {
      console.error("Error sending message:", err);
    } finally {
      setIsTyping(false);
    }
  };

  // New chat handlers
  const handleNewChatClick = () => {
    if (messages.length > 0) {
      setShowNewChatWarning(true);
    } else {
      handleNewChatSession();
    }
  };

  const handleSaveAndProceed = () => {
    setShowNewChatWarning(false);
    setSaveChatModalOpen(true);
  };

  const handleProceedWithoutSaving = () => {
    setShowNewChatWarning(false);
    handleNewChatSession();
  };

  const handleSaveChat = () => {
    const chatContent = messages
      .map((msg) => `[${msg.sentTime}] ${msg.sender}: ${msg.message}`)
      .join("\n");

    const element = document.createElement("a");
    const file = new Blob([chatContent], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = `${chatFilename}.txt`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    URL.revokeObjectURL(element.href);

    setSaveChatModalOpen(false);
    setChatFilename("");
    handleNewChatSession();
  };

  const handleModalClose = () => {
    setSaveChatModalOpen(false);
    setChatFilename("");
    setPendingNewChat(false);
  };

  const handleNewChatSession = () => {
    setMessages([]);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        minHeight: "100%",
        bgcolor: "#282c34",
        p: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      {/* Header */}
      <Box
        sx={{ marginLeft: "-25px", marginTop: "-25px" }}
        className="main-content-header"
      >
        <p>
          Generate drafts, brainstorm ideas, summarize and translate texts via
          chat.
        </p>
      </Box>

      {/* Controls Section */}
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, mb: 2 }}>
        {/* LLM Choice */}
        <FormControl variant="filled" sx={{ minWidth: 200 }}>
          <InputLabel id="llm-choice-label" sx={{ color: "white" }}>
            Select LLM
          </InputLabel>
          <Tooltip
            title="Select LLM for generating texts. Defaults are fine for most use cases."
            arrow
            placement="top"
          >
            <Select
              style={{
                backgroundColor: "#444",
                color: "darkgrey",
                fontSize: "14px",
                borderRadius: "4px",
              }}
              labelId="llm-choice-label"
              id="llm-choice"
              value={llmChoice}
              onChange={handleLlmChoiceChange}
            >
              <MenuItem value="Claude 3.5 Haiku">Claude 3.5 Haiku</MenuItem>
              <MenuItem value="Claude 3.7 Sonnet">Claude 3.7 Sonnet</MenuItem>
              <MenuItem value="DeepSeek V3">DeepSeek V3</MenuItem>
              <MenuItem value="Gemini 2.0 Flash">Gemini 2.0 Flash</MenuItem>
              <MenuItem value="Gemini 2.5 Pro Experimental">Gemini 2.5 Pro Experimental</MenuItem>
              <MenuItem value="GPT-3.5 Turbo">GPT-3.5 Turbo</MenuItem>
              <MenuItem value="GPT-4o (latest)">GPT-4o (latest)</MenuItem>
              <MenuItem value="GPT-4o">GPT-4o</MenuItem>
              <MenuItem value="GPT-4o mini">GPT-4o mini</MenuItem>
              <MenuItem value="Groq Llama-3.3-70b">
                Groq Llama-3.3-70b
              </MenuItem>
              <MenuItem value="Mistral Large">Mistral Large</MenuItem>
            </Select>
          </Tooltip>
        </FormControl>

        {/* Task Choice */}
        <FormControl variant="filled" sx={{ minWidth: 200 }}>
          <InputLabel id="task-choice-label" sx={{ color: "white" }}>
            Select Task
          </InputLabel>
          <Select
            labelId="task-choice-label"
            id="task-choice"
            value={taskChoice}
            onChange={handleTaskChoiceChange}
            style={{
              backgroundColor: "#444",
              color: "darkgrey",
              fontSize: "14px",
              borderRadius: "4px",
            }}
          >
            {displayedTasks.map((task) => (
              <MenuItem key={task} value={task}>
                {task}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Language Selection for Press Releases */}
        {[
          "Press Release",
          "Press Statement",
          "Makabayan Press Statement",
        ].includes(taskChoice) && (
          <FormControl variant="filled" sx={{ minWidth: 200 }}>
            <InputLabel
              id="press-release-language-label"
              sx={{ color: "white" }}
            >
              Select Language
            </InputLabel>
            <Select
              labelId="press-release-language-label"
              id="press-release-language"
              value={pressReleaseLanguage}
              onChange={(e) => setPressReleaseLanguage(e.target.value)}
              style={{
                backgroundColor: "#444",
                color: "darkgrey",
                fontSize: "14px",
                borderRadius: "4px",
              }}
            >
              <MenuItem value="English">English</MenuItem>
              <MenuItem value="English & Filipino">English & Filipino</MenuItem>
              <MenuItem value="Filipino">Filipino</MenuItem>
            </Select>
          </FormControl>
        )}

        {/* Translation Language Selection */}
        {taskChoice === "Translation" && (
          <FormControl variant="filled" sx={{ minWidth: 200 }}>
            <InputLabel id="language-choice-label" sx={{ color: "white" }}>
              Translate to
            </InputLabel>
            <Select
              labelId="language-choice-label"
              id="language-choice"
              value={languageChoice}
              onChange={handleLanguageChoiceChange}
              style={{
                backgroundColor: "#444",
                color: "darkgrey",
                fontSize: "14px",
                borderRadius: "4px",
              }}
            >
              <MenuItem value="Tagalog">Tagalog</MenuItem>
              <MenuItem value="English">English</MenuItem>
              <MenuItem value="Cebuano">Cebuano</MenuItem>
              <MenuItem value="Ilocano">Ilocano</MenuItem>
              <MenuItem value="Ilonggo">Ilonggo</MenuItem>
              <MenuItem value="Waray">Waray</MenuItem>
            </Select>
          </FormControl>
        )}

        {/* Summary Style Selection */}
        {taskChoice === "Summary" && (
          <FormControl variant="filled" sx={{ minWidth: 200 }}>
            <InputLabel id="summary-style-choice-label" sx={{ color: "white" }}>
              Summary Style
            </InputLabel>
            <Select
              labelId="summary-style-choice-label"
              id="summary-style-choice"
              value={summaryStyleChoice}
              onChange={handleSummaryStyleChoiceChange}
              style={{
                backgroundColor: "#444",
                color: "darkgrey",
                fontSize: "14px",
                borderRadius: "4px",
              }}
            >
              <MenuItem value="Bullets">Bullets</MenuItem>
              <MenuItem value="Powerpoint">Powerpoint</MenuItem>
              <MenuItem value="Notes & Insights">Notes & Insights</MenuItem>
              <MenuItem value="Narrative">Narrative</MenuItem>
              <MenuItem value="Q & A Explainer">Q & A Explainer</MenuItem>
            </Select>
          </FormControl>
        )}
      </Box>

      {/* Main Content Area */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 2,
          height: { xs: "auto", md: "calc(100vh - 200px)" },
          overflow: { md: "hidden" },
        }}
      >
        {/* Left Column - Chat Interface */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            minHeight: { xs: "500px", md: "100%" },
          }}
        >
          <AdaptiveChatContainer messages={messages}>
            <CustomChatInterface
              messages={messages}
              isTyping={isTyping}
              onSendMessage={handleSendMessage}
              disabled={false}
              selectedDocument={null}
              placeholder="Chat here. Press ➤ or Ctrl+ENTER to send."
              emptyStateMessage={chatPlaceholder}
              alwaysShowSubmit={true}
              sx={{
                height: "100%",
                maxHeight: "100%",
                border: "1px solid #444",
                borderRadius: 1,
                bgcolor: "#282c34",
                display: "flex",
                flexDirection: "column",
                width: "99%", // Ensure full width
                scrollMarginTop: { xs: "80px", md: 0 }, // Add scroll margin for header
              }}
            />
          </AdaptiveChatContainer>

          <StyledButton
            variant="contained"
            onClick={handleNewChatClick}
            sx={{
              alignSelf: "flex-start",
              mt: -0.5,
            }}
          >
            Start New Chat
          </StyledButton>
        </Box>

        {/* Right Column - Text Boxes */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            // Correctly formatted WebKit scrollbar styles
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#444",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#666",
              borderRadius: "4px",
              "&:hover": {
                backgroundColor: "#888",
              },
            },
            gap: 2,
            overflowY: "auto",
            height: { xs: "auto", md: "100%" },
          }}
        >
          <TextInput
            label="Scratch Pad"
            placeholder="Collate and edit here. Save as text or PPTX."
            value={scratchPadText}
            onChange={(e) => setScratchPadText(e.target.value)}
            className="input-container"
            tabContext="WriteTab"
            showSaveIcon={true}
            customButton={
              <Tooltip title="Save as PPTX file">
                <span>
                  <StyledButton
                    variant="contained"
                    onClick={handleOpenSavePptxModal} // Changed from onClick={() => setSavePptxModalOpen(true)}
                    disabled={isPptxGenerating || !scratchPadText.trim()}
                    isGenerating={isPptxGenerating}
                  >
                    {isPptxGenerating && (
                      <FontAwesomeIcon
                        icon={faSpinner}
                        spin
                        className="spinner"
                        sx={{ mr: 1 }}
                      />
                    )}
                    <span className="button-text">PPTX</span>
                  </StyledButton>
                </span>
              </Tooltip>
            }
          />

          <TextInput
            label="Session History"
            placeholder="Text input and output for this session"
            value={draftHistory}
            readOnly={true}
            className="input-container"
            tabContext="WriteTab"
            showSaveIcon={true}
          />

          {/* <TextBox
            label="Text from URLs"
            placeholder="Fetched text from URLs will appear here."
            value={sourceText}
            onChange={(e) => setSourceText(e.target.value)}
            className="input-container generated-text-container"
            tabContext="WriteTab"
            isBasicToolbar={true}
          />

          {fetchError && (
            <Box
              sx={{
                color: "error.main",
                mt: 1,
                p: 2,
                bgcolor: "error.light",
                borderRadius: 1,
                typography: "body2",
              }}
            >
              {fetchError}
            </Box>
          )} */}
        </Box>
      </Box>

      {/* New Chat Warning Dialog */}
      <NewChatWarningDialog
        isOpen={showNewChatWarning}
        onClose={() => setShowNewChatWarning(false)}
        onProceed={handleProceedWithoutSaving}
        onSaveChat={handleSaveAndProceed}
      />

      {/* Save Chat Modal */}
      <SaveFileModal
        open={saveChatModalOpen}
        onClose={handleModalClose}
        onSave={handleSaveChat}
        title="Save Chat History"
        filename={chatFilename}
        onFilenameChange={(e) => setChatFilename(e.target.value)}
        placeholder="chat_history"
        helperText=".txt will be automatically added"
        fileExtension=".txt"
      />

      {/* PowerPoint Save Modal */}
      <SaveFileModal
        open={savePptxModalOpen}
        onClose={() => {
          setSavePptxModalOpen(false);
          setPptxFilename("");
        }}
        onSave={handleSaveAsPPTX}
        title="Save PowerPoint Presentation"
        filename={pptxFilename}
        onFilenameChange={(e) => setPptxFilename(e.target.value)}
        placeholder={suggestedPptxFilename || "presentation"}
        helperText=".pptx will be automatically added"
        fileExtension=".pptx"
      />
    </Box>
  );
};

export default WriteTab;
