import React from "react";

const ChangeLog = () => {
  const changelogItems = [
    {
      date: "3-31-2025",
      changes: [
        "Added Gemini 2.5 Pro Experimental (with 1M context window and 64K max tokens output) in Write and Chat with PDF tabs",
      ],
    },
    {
      date: "2-25-2025",
      changes: [
        "Updated to Claude 3.7 Sonnet in Write Tab",
      ],
    },
    {
      date: "2-13-2025",
      changes: [
        "Scanned PDFs may now be uploaded and queried in Take Notes tab", 
        "Added Mistral Large model with 131K token context window in Write tab",       
      ],
    },
    {
      date: "2-12-2025",
      changes: [
        "Updated to google-genai 1.1.0 SDK",      
        "Fixed background text fetching from URLs in Write tab",
        "Pressing 'Enter' will no longer send user chat message"
      ],
    },
    {
      date: "1-28-2025",
      changes: [
        "Updated Take Notes tab to Gemini 2.0 Flash model; removed Gemini Files API logic; increased maximum file size upload to 25MB",
      ],
    },
    {
      date: "1-02-2025",
      changes: [
        "Added Gemini 2.0 Flash model with 1M token context window and 8K max tokens output in Write tab",
        "Set max tokens output for GPT-3.5 Turbo (4096); Claude 3.5 Haiku, Claude 3.5 Sonnet, Gemini 2.0 Flash, DeepSeek V3 (8192); GPT-4o (latest), GPT-4o, GPT-4o mini (16384);Groq Llama-3.3-70b Versatile (32768)",
      ],
    },
    {
      date: "12-27-2024",
      changes: [
        "Added DeepSeek V3 model with 64K token context window and 8K max tokens output",
      ],
    },
    {
      date: "12-08-2024",
      changes: [
        "Added Groq Llama 3.3 70B model with 128K token context window and 32K max tokens output",
      ],
    },
    {
      date: "12-11-2024",
      changes: [
        'Major UI update: implemented chat interface in "Write" tab',
        'Implemented persistence after page refresh in "Write" tab',
      ],
    },
    {
      date: "12-01-2024",
      changes: ['Implemented "Metrics" tab in admin dashboard'],
    },
    {
      date: "11-28-2024",
      changes: [
        'Added Save as RTF button in "Chat with PDF" tab',
        'Refinements to "Chat with PDF" tab and PDF viewer component',
        'Added rate limits to "Chat with PDF" tab endpoints',
        "Added standard SaveFileModal for all save buttons, with file naming option",
      ],
    },
    {
      date: "11-27-2024",
      changes: [
        'Added Copy button, markdown view, other tweaks and refinements to "Chat with PDF" tab',
      ],
    },
    {
      date: "11-25-2024",
      changes: [
        'Total overhaul of "Chat with PDF" tab: 1) new user interface; 2) upload multiple PDF files for querying; 3) Gemini 1.5 Flash under the hood with 1M token context window',
      ],
    },
    {
      date: "11-11-2024",
      changes: [
        'Removed "Summarize Articles" tab. All its functionality incorporated into "Summary" task in Write tab.',
        'In Write tab, added "Fetch" button and URL Text box for fetching and displaying text from URLs. For use as web-scraping utility to fetch text for LLM use.',
        "Modularized changelog updates in ChangLog component.",
        'In Write tab, added "Select Language" dropdown for "Press Release," "Press Statement," and "Makabayan Press Statement" tasks. Choose to draft in English, English & Filipino, or Filipino.',
      ],
    },
    {
      date: "11-10-2024",
      changes: [
        "Merged Summarize PDF tabs v.1 and v.2",
        "Summary/Outline task in Write tab may now summarize from URLs",
      ],
    },
    {
      date: "11-08-2024",
      changes: [
        'Added "Upscale an image" task in Create Image tab. Upscale low resolution image with Recraft API - Clarity Upscale.',
        "Upgraded to FLUX 1.1 [pro] ultra in Create Image tab",
      ],
    },
    {
      date: "11-05-2024",
      changes: ["Added Anthropic Claude 3.5 Haiku"],
    },
    {
      date: "10-30-2024",
      changes: [
        'Added "Show Timestamps" toggle switch in Transcribe Audio tab. Transcripts may be saved with and without timestamps.',
        "Temporary: show separate tabs for Summarize PDF v.1 and v.2",
      ],
    },
    {
      date: "10-29-2024",
      changes: [
        'New "Q & A Explainer" option added in Write and Summarize Articles tabs',
        'Found and fixed bug in "Notes & Insights" option in Summarize Articles tab',
      ],
    },
    {
      date: "10-26-2024",
      changes: [
        "Updated to FLUX.1.1 [pro] in Create Image tab",
        "Set updated Claude 3.5 Sonnet as default LLM for most writing tasks in Write tab",
      ],
    },
    {
      date: "10-23-2024",
      changes: ["Updated Claude 3.5 Sonnet to latest version 20241022"],
    },
    {
      date: "09-19-2024",
      changes: [
        "Refactored for more robust user and organization management logic",
      ],
    },
    {
      date: "09-18-2024",
      changes: [
        'Added chatgpt-4o-latest model in Write tab as "GPT-4o (latest)" - "Dynamic model continuously updated to the current version of GPT-4o in ChatGPT."',
      ],
    },
    {
      date: "08-23-2024",
      changes: ["Added FLUX Realism LoRA model in Create Image tab"],
    },
    {
      date: "08-19-2024",
      changes: [
        "Revamped Summarize PDF logic (leveraging long context window and new prompt caching feature in Claude API; chunking based on document outline) Note: barebones implementation without token tracking.",
      ],
    },
    {
      date: "08-08-2024",
      changes: [
        "Updated to GPT-4o to gpt-4o-2024-08-06 (support for structured outputs; 16k max output tokens; lower cost).",
        "Made login error messages more secure.",
      ],
    },
    {
      date: "08-01-2024",
      changes: [
        "Added FLUX.1 (pro, dev, and schnell) models to Create Image tab. FLUX.1 [schnell] as new default for text-to-image function.",
      ],
    },
    {
      date: "07-24-2024",
      changes: ["Added Groq Llama-3.1-70b-Verstaile model to Write tab"],
    },
    {
      date: "07-19-2024",
      changes: [
        "Added OpenAI GPT-4o mini model in Write tab; used in Summarize PDF and Chat with PDF tabs.",
        "Revised Usage Information",
      ],
    },
    {
      date: "07-17-2024",
      changes: [
        "Added negative prompt to create and transform image tasks in Create Image tab",
        "Implemented more secure password requirement for new users in Registration page",
      ],
    },
    {
      date: "07-12-2024",
      changes: [
        "Added number of images selection for image creation task in Create Images tab.",
      ],
    },
    {
      date: "07-05-2024",
      changes: [
        "Added 'PPTX' button to convert and save text to Powerpoint file in Scratch Pad in Write and Summarize Articles tabs",
        "Replaced wand icon with button in Write and Summarize Articles tabs.",
      ],
    },
    {
      date: "07-04-2024",
      changes: [
        "Implemented cost tracking for audio transcription and integrated it with cost tracking for text and image generation.",
      ],
    },
    {
      date: "07-03-2024",
      changes: [
        "Implemented cost tracking for image generation and integrated it with cost tracking for text generation.",
      ],
    },
    {
      date: "06-29-2024",
      changes: [
        "Added Transcribe Audio tab for transcription of audio files with Whisper v3.",
      ],
    },
    {
      date: "06-28-2024",
      changes: [
        "Added image transformation task with Stable Diffusion 1.5 Depth ControlNet in Create Image tab.",
      ],
    },
    {
      date: "06-27-2024",
      changes: ["Added Enhance Prompt switch in Create Image tab."],
    },
    {
      date: "06-26-2024",
      changes: [
        "Initial implementation of Create Image tab. Uses Stable Diffusion models to generate images from text.",
      ],
    },
    {
      date: "06-21-2024",
      changes: [
        "Upgraded to Anthropic Claude 3.5 Sonnet model in Write and Summarize Articles tabs",
      ],
    },
    {
      date: "06-16-2024",
      changes: ["Added new error handling in Write tab"],
    },
    {
      date: "06-03-2024",
      changes: ["Integrated Cloudflare CDN and security features"],
    },
    {
      date: "05-28-2024",
      changes: [
        "Added Anthropic Claude 3 Opus model in Write tab",
        "Adjusted max_tokens in Write tab",
        "Renamed Take Notes tab as Chat with PDF",
        "Other UI tweaks",
      ],
    },
    {
      date: "05-27-2024",
      changes: ["Changed LLM defaults in Write tab"],
    },
    {
      date: "05-25-2024",
      changes: [
        "Swapped out ChromaDB for FAISS as vector store in Take Notes tab",
        "Improved responsive layout of Login and Registration pages",
      ],
    },
    {
      date: "05-16-2024",
      changes: [
        "Added OpenAI GPT-4o model in Write and Summarize Articles tabs",
      ],
    },
    {
      date: "04-30-2024",
      changes: [
        "New feature: in Write tab⇒Press Release, Press Statement, and Makabayan Press Statement tasks, just paste URL link to news article in Your Prompt box then click yellow wand button to generate draft. The text content in the link will be fetched and used as context for your draft. You can type additional instructions after the URL link to give specific guidance on how the draft will be written.",
      ],
    },
    {
      date: "04-24-2024",
      changes: [
        "Added Anthropic Claude 3 Sonnet and Groq Llama-3 70B 8K models in Write tab",
      ],
    },
    {
      date: "04-02-2024",
      changes: [
        "Material UI icons in app bar",
        "Improved placeholder texts in Write tab",
      ],
    },
    {
      date: "04-01-2024",
      changes: [
        "Added Anthropic Claude 3 Haiku model (selectable in Write tab and used in Summarize Articles tab)",
        "Added Summary Styles dropdown for Summary/Outline task in Write tab",
        "Session-wide token usage tracking",
        "What's New section in side drawer",
      ],
    },
  ];

  return (
    <div
      className="drawer-whatsnew-textarea"
      style={{
        backgroundColor: "#282c34",
        color: "#f0f0f0",
        padding: "10px",
        height: "50vh",
        overflowY: "auto",
        fontSize: "12px",
        border: "1px solid #444",
        borderRadius: "4px",
      }}
    >
      {changelogItems.map((item, index) => (
        <div key={index}>
          <p style={{ margin: 0 }}>{item.date}</p>
          <ul style={{ lineHeight: "1.5", paddingLeft: "20px" }}>
            {item.changes.map((change, changeIndex) => (
              <li key={changeIndex}>{change}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default ChangeLog;
