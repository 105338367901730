import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../AuthProvider";
import { useExchangeRate } from "../ExchangeRateProvider";
import { useSessionData } from "../SessionDataProvider";
import { useTokenTracker } from "../TokenTrackerContext";
import LoginForm from "../components/LoginForm";
import AdminDashboard from "../components/AdminDashboard";
import Tabs, { Tab } from "../components/Tabs";
import AppBarComponent from "../components/AppBarComponent";
import DrawerComponent from "../components/DrawerComponent";
import WriteTab from "../components/WriteTab";
import UnifiedPdfSummaryTab from "../components/UnifiedPdfSummaryTab";
import TakeNotesTab from "../components/TakeNotesTab";
import CreateImageTab from "../components/CreateImageTab";
import { upscaleImage } from "../api/apiCalls";
import { scrapeUrls } from "../api/apiCalls";

import TranscribeAudioTab from "../components/TranscribeAudioTab";
import "./HomePage.css";
import { handleTaskRequest } from "../utils/taskUtils";
import { useTask } from "../TaskContext";

// Material-UI Imports
import Box from "@mui/material/Box";

const HomePage = () => {
  const { isAuthenticated, userRole } = useAuth();
  const [showDashboard, setShowDashboard] = useState(false);

  const [writeTabMessages, setWriteTabMessages] = useState(() => {
    const saved = localStorage.getItem("writeTabMessages");
    return saved ? JSON.parse(saved) : [];
  });
  const [writeTabIsTyping, setWriteTabIsTyping] = useState(false);
  const [writeTabSavePptxModalOpen, setWriteTabSavePptxModalOpen] =
    useState(false);
  const [writeTabPptxFilename, setWriteTabPptxFilename] = useState("");
  const [writeTabIsPptxGenerating, setWriteTabIsPptxGenerating] =
    useState(false);

  const [prompt, setPrompt] = useState("");
  const [generatedText, setGeneratedText] = useState("");
  const { exchangeRate } = useExchangeRate(); // Access exchange rate info
  const [scratchPadText, setScratchPadText] = useState(() => {
    const saved = localStorage.getItem("writeTabScratchPad");
    return saved || "";
  });
  const [chatHistory, setChatHistory] = useState(() => {
    const saved = localStorage.getItem("writeTabChatHistory");
    return saved || "";
  });
  const [sourceText, setSourceText] = useState("");
  const [nonChatDraftHistory, setNonChatDraftHistory] = useState(() => {
    const saved = localStorage.getItem("writeTabNonChatDraftHistory");
    return saved || "";
  });
  const [draftHistory, setDraftHistory] = useState(() => {
    const saved = localStorage.getItem("writeTabOutputHistory");
    return saved || "";
  });

  const [brainstormHistory, setBrainstormHistory] = useState(() => {
    const saved = localStorage.getItem("writeTabBrainstormHistory");
    return saved || "";
  });
  const [isNewChatSession, setIsNewChatSession] = useState(true);
  const [taskChoice, setTaskChoice] = useState(""); // Default option
  const [currentTask, setCurrentTask] = useState("");
  const [llmChoice, setLlmChoice] = useState(""); // State for the LLM choice
  const [languageChoice, setLanguageChoice] = useState("Tagalog"); // State for the language choice
  const [drawerOpen, setDrawerOpen] = useState(false); // State for drawer
  const { updateTokenData } = useTokenTracker();
  const [tokenInfo, setTokenInfo] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [summaryStyleChoice, setSummaryStyleChoice] = useState("Bullets");
  const [fileUrl, setFileUrl] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(0.6);
  const [outputHistory, setOutputHistory] = useState("");
  //const [notesFileUrl, setNotesFileUrl] = useState("");
  const [notesNumPages, setNotesNumPages] = useState(0);
  const [notesPageNumber, setNotesPageNumber] = useState(1);
  const [notesScale, setNotesScale] = useState(1);
  const [notesMessages, setNotesMessages] = useState([]);
  const { sessionData, setSessionData } = useSessionData();
  const [userSelectedLLM, setUserSelectedLLM] = useState(false);
  const [imageModel, setImageModel] = useState("fal-ai/flux/schnell");
  const [imagePrompt, setImagePrompt] = useState("");
  const [generatedImageUrl, setGeneratedImageUrl] = useState("");
  const [isGeneratingImage, setIsGeneratingImage] = useState(false);
  const [enhancePrompt, setEnhancePrompt] = useState(false);
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");
  const [transformedImageUrl, setTransformedImageUrl] = useState("");
  const [imageTask, setImageTask] = useState("create");
  const [audioTask, setAudioTask] = useState("transcribe");
  const [audioLanguage, setAudioLanguage] = useState("tl");
  const [audioFile, setAudioFile] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [transcriptionResult, setTranscriptionResult] = useState("");
  const [transcriptionResponse, setTranscriptionResponse] = useState(null);
  const [isAudioLoading, setIsAudioLoading] = useState(false);
  //const handleToggleForms = () => setShowLoginForm(!showLoginForm); // Function to toggle forms
  const { setUsageTask, updateTaskUsage } = useTask();
  const [uploadedFile, setUploadedFile] = useState(null);
  const [upscaledImageUrl, setUpscaledImageUrl] = useState(null);
  const cleanupRef = React.useRef({ url: null, currentTask: null });
  const [summaryMode, setSummaryMode] = useState("simple");
  const [fetchError, setFetchError] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [pressReleaseLanguage, setPressReleaseLanguage] =
    useState("English & Filipino");

  // Add new states for document management
  //const [setNoteDocuments] = useState([]); // List of uploaded documents
  const [selectedDocument, setSelectedDocument] = useState(null); // Currently selected document
  const [notesError, setNotesError] = useState("");
  const [notesIsError, setNotesIsError] = useState(false);
  const [notesIsTyping, setNotesIsTyping] = useState(false);
  const [documentViewerUrls, setDocumentViewerUrls] = useState({});
  const [documents, setDocuments] = useState([]);
  const [pdfFiles, setPdfFiles] = useState({});
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const toggleDashboard = () => {
    if (userRole === "admin") {
      setShowDashboard(!showDashboard);
    }
  };

  const handleLlmChoiceChange = useCallback(
    (e) => {
      setUserSelectedLLM(true);
      setLlmChoice(e.target.value);
    },
    [setUserSelectedLLM, setLlmChoice]
  ); // Dependencies necessary for useCallback

  const handleTaskChoiceChange = (e) => {
    setTaskChoice(e.target.value);
  };

  // Keep track of blob URLs
  const urlsToCleanup = React.useRef({});

  // Create URLs only when files are first uploaded
  const handleDocumentUpload = useCallback((files, response) => {
    if (!Array.isArray(response)) {
      // Single file upload
      const url = URL.createObjectURL(files[0]);

      setPdfFiles((prev) => ({
        ...prev,
        [response.filename]: files[0],
      }));

      setDocumentViewerUrls((prev) => ({
        ...prev,
        [response.filename]: url,
      }));

      urlsToCleanup.current[response.filename] = url;
      setDocuments([response]);
      setSelectedDocument(response);
      setNotesPageNumber(1);
    } else {
      // Multiple files upload
      const newFiles = {};
      const newUrls = {};

      response.forEach((doc, index) => {
        const url = URL.createObjectURL(files[index]);
        newFiles[doc.filename] = files[index];
        newUrls[doc.filename] = url;
        urlsToCleanup.current[doc.filename] = url;
      });

      setPdfFiles((prev) => ({
        ...prev,
        ...newFiles,
      }));

      setDocumentViewerUrls((prev) => ({
        ...prev,
        ...newUrls,
      }));

      setDocuments(response);
      setSelectedDocument(response[0]);
      setNotesPageNumber(1);
    }
  }, []);

  // Effect to maintain URL persistence and handle cleanup when documents change
  useEffect(() => {
    // Create a Set of current document filenames for efficient lookup
    const currentDocumentFilenames = new Set(
      documents.map((doc) => doc.filename)
    );

    // Recreate URLs only for documents that need them
    documents.forEach((doc) => {
      const filename = doc.filename;
      if (pdfFiles[filename] && !documentViewerUrls[filename]) {
        // Only create new URL if we have the file but no valid URL
        const url = URL.createObjectURL(pdfFiles[filename]);
        urlsToCleanup.current[filename] = url;
        setDocumentViewerUrls((prev) => ({
          ...prev,
          [filename]: url,
        }));
      }
    });

    // Clean up any URLs for documents that no longer exist
    Object.keys(documentViewerUrls).forEach((filename) => {
      if (!currentDocumentFilenames.has(filename)) {
        // Document no longer exists, clean up its URL
        const url = urlsToCleanup.current[filename];
        if (url) {
          URL.revokeObjectURL(url);
          delete urlsToCleanup.current[filename];

          setDocumentViewerUrls((prev) => {
            const newUrls = { ...prev };
            delete newUrls[filename];
            return newUrls;
          });
        }
      }
    });
  }, [documents, pdfFiles, documentViewerUrls]);

  // Effect to validate and recreate invalid URLs
  useEffect(() => {
    const validateAndRecreateUrls = async () => {
      for (const doc of documents) {
        const filename = doc.filename;
        const currentUrl = documentViewerUrls[filename];

        if (currentUrl && pdfFiles[filename]) {
          try {
            // Try to access the current URL
            const response = await fetch(currentUrl, { method: "HEAD" });
            if (!response.ok) {
              throw new Error("URL invalid");
            }
          } catch {
            // URL is invalid, recreate it
            if (urlsToCleanup.current[filename]) {
              URL.revokeObjectURL(urlsToCleanup.current[filename]);
            }
            const newUrl = URL.createObjectURL(pdfFiles[filename]);
            urlsToCleanup.current[filename] = newUrl;
            setDocumentViewerUrls((prev) => ({
              ...prev,
              [filename]: newUrl,
            }));
          }
        }
      }
    };

    validateAndRecreateUrls();
  }, [documents, documentViewerUrls, pdfFiles]);

  // Final cleanup on unmount
  useEffect(() => {
    return () => {
      // Create a snapshot of URLs to clean up
      const urlsToRevoke = { ...urlsToCleanup.current };

      // Clean up all blob URLs
      Object.entries(urlsToRevoke).forEach(([filename, url]) => {
        if (url && url.startsWith("blob:")) {
          URL.revokeObjectURL(url);
        }
      });

      // Clear the ref
      urlsToCleanup.current = {};
    };
  }, []); // Empty dependency array since this is only for unmount

  useEffect(() => {
    // Automatically set LLM choice based on the selected task
    switch (taskChoice) {
      case "Freeform":
      case "Brainstorm":
      case "Chat":
        setLlmChoice("DeepSeek V3");
        break;
      case "Summary":
      case "Q & A Explainer":
      case "House Bill":
      case "House Resolution":
      case "Explanatory Note":
        setLlmChoice("Gemini 2.5 Pro Experimental");
        break;
      case "Press Release":
      case "Press Statement":
      case "Makabayan Press Statement":
        setLlmChoice("Claude 3.7 Sonnet");
        break;
      case "Translation":
        setLlmChoice("Gemini 2.5 Pro Experimental");
        break;
      // Add more cases here for different tasks as needed
      default:
        // Optionally set a default LLM choice for any task not explicitly handled
        break;
    }
  }, [taskChoice]);

  useEffect(() => {
    // Automatically set LLM choice based on the selected summary style
    switch (summaryStyleChoice) {
      case "Bullets":
        setLlmChoice("DeepSeek V3");
        break;
      case "Powerpoint":
        setLlmChoice("DeepSeek V3");
        break;
      case "Notes & Insights":
        setLlmChoice("DeepSeek V3");
        break;
      case "Narrative":
        setLlmChoice("GPT-3.5 Turbo");
        break;
      case "Q & A Explainer":
        setLlmChoice("DeepSeek V3");
        break;
      default:
        setLlmChoice("DeepSeek V3"); // Default to Haiku for simpler summaries
        break;
    }
  }, [summaryStyleChoice]);

  const handleSummaryStyleChoiceChange = (e) => {
    setSummaryStyleChoice(e.target.value); // Update the state with the new summary style choice
  };

  const handleLanguageChoiceChange = (e) => {
    setLanguageChoice(e.target.value); // Update the state with the new language choice
  };

  const handleTokenCountUpdate = (tokenCount) => {
    setTokenInfo(tokenCount);
  };

  const handleGenerateClick = async () => {
    setIsGenerating(true);

    let modifiedPrompt = prompt;

    // Modify prompt based on language selection for relevant tasks
    if (
      [
        "Press Release",
        "Press Statement",
        "Makabayan Press Statement",
      ].includes(taskChoice)
    ) {
      if (pressReleaseLanguage === "English & Filipino") {
        modifiedPrompt = `${prompt}\n\nInclude quotes in English and Filipino.`;
      } else if (pressReleaseLanguage === "Filipino") {
        modifiedPrompt = `${prompt}\n\nWrite statement in Filipino.`;
      }
    }

    let usageTaskName = taskChoice;
    if (taskChoice === "Summary") {
      usageTaskName += `-${summaryStyleChoice}`;
    } else if (taskChoice === "Translation") {
      usageTaskName += `-${languageChoice}`;
    }
    setUsageTask(usageTaskName);
    updateTaskUsage(usageTaskName);

    const taskStateValues = {
      currentTask,
      prompt: modifiedPrompt, // Use the modified prompt here
      brainstormHistory,
      chatHistory,
      sessionData,
      setSessionData,
      updateTokenData,
      summaryStyleChoice,
    };

    const setTaskStates = {
      setCurrentTask,
      setIsNewChatSession,
      setBrainstormHistory,
      setChatHistory,
      setNonChatDraftHistory,
      setGeneratedText,
      setTokenInfo,
      isNewChatSession,
    };

    const unifiedEndpoint = "/generate/";

    await handleTaskRequest(
      taskChoice,
      unifiedEndpoint,
      llmChoice,
      languageChoice,
      summaryStyleChoice,
      setTaskStates,
      taskStateValues,
      exchangeRate
    );

    setIsGenerating(false);
  };

  useEffect(() => {
    if (taskChoice === "Brainstorm") {
      // Set Draft History to the Brainstorm history
      setDraftHistory(brainstormHistory);
    } else if (taskChoice === "Chat") {
      // Set Draft History to the Chat history
      setDraftHistory(chatHistory);
    } else {
      // For other tasks, use the non-chat draft history
      setDraftHistory(nonChatDraftHistory);
    }
  }, [taskChoice, brainstormHistory, chatHistory, nonChatDraftHistory]);

  // Add this new function without modifying existing handleGenerateClick
  const handleFetchText = async (prompt) => {
    if (!prompt.trim()) return;

    setIsFetching(true);
    try {
      const { source_texts, error_messages } = await scrapeUrls(prompt);

      setSourceText(source_texts);

      if (error_messages?.length > 0) {
        console.warn("Scraping errors:", error_messages);
        setFetchError(error_messages.join("\n"));
      }
    } catch (error) {
      console.error("Error fetching text:", error);
      setFetchError(error.message || "Error fetching text");
    } finally {
      setIsFetching(false);
    }
  };

  React.useEffect(() => {
    // Update ref when these values change
    cleanupRef.current = { url: uploadedImageUrl, currentTask: imageTask }; // You'll need to lift up 'task' state too

    return () => {
      const { url, currentTask } = cleanupRef.current;
      if (
        url &&
        currentTask === "upscale" &&
        !document.body.contains(document.activeElement)
      ) {
        URL.revokeObjectURL(url);
      }
    };
  }, [uploadedImageUrl, imageTask]); // Include task in dependencies

  // Persist messages to localStorage
  useEffect(() => {
    localStorage.setItem("writeTabMessages", JSON.stringify(writeTabMessages));
  }, [writeTabMessages]);

  // Persist scratch pad text
  useEffect(() => {
    localStorage.setItem("writeTabScratchPad", scratchPadText);
  }, [scratchPadText]);

  // Persist output history
  useEffect(() => {
    localStorage.setItem("writeTabOutputHistory", draftHistory);
  }, [draftHistory]);

  // Persist source text
  useEffect(() => {
    localStorage.setItem("writeTabSourceText", sourceText);
  }, [sourceText]);

  useEffect(() => {
    localStorage.setItem("writeTabBrainstormHistory", brainstormHistory);
  }, [brainstormHistory]);

  useEffect(() => {
    localStorage.setItem("writeTabChatHistory", chatHistory);
  }, [chatHistory]);

  useEffect(() => {
    localStorage.setItem("writeTabNonChatDraftHistory", nonChatDraftHistory);
  }, [nonChatDraftHistory]);

  // Cleanup on logout
  // Update the logout cleanup to clear these as well
  useEffect(() => {
    return () => {
      if (!isAuthenticated) {
        localStorage.removeItem("writeTabMessages");
        localStorage.removeItem("writeTabScratchPad");
        localStorage.removeItem("writeTabOutputHistory");
        localStorage.removeItem("writeTabSourceText");
        localStorage.removeItem("writeTabBrainstormHistory");
        localStorage.removeItem("writeTabChatHistory");
        localStorage.removeItem("writeTabNonChatDraftHistory");
      }
    };
  }, [isAuthenticated]);

  // Function to clear all WriteTab state
  const clearWriteTabState = useCallback(() => {
    setWriteTabMessages([]);
    setScratchPadText("");
    setDraftHistory("");
    setSourceText("");
    setBrainstormHistory("");
    setChatHistory("");
    setNonChatDraftHistory("");

    localStorage.removeItem("writeTabMessages");
    localStorage.removeItem("writeTabScratchPad");
    localStorage.removeItem("writeTabOutputHistory");
    localStorage.removeItem("writeTabSourceText");
    localStorage.removeItem("writeTabBrainstormHistory");
    localStorage.removeItem("writeTabChatHistory");
    localStorage.removeItem("writeTabNonChatDraftHistory");
  }, []);

  if (isAuthenticated) {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBarComponent
          toggleDrawer={toggleDrawer}
          toggleDashboard={toggleDashboard}
          showHomePage={() => setShowDashboard(false)}
          tokenInfo={tokenInfo}
        />
        <DrawerComponent
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          toggleDrawer={toggleDrawer}
          tokenInfo={tokenInfo}
        />
        {showDashboard ? (
          // Render AdminDashboard if showDashboard is true
          <AdminDashboard />
        ) : (
          // Otherwise, render the homepage content
          <div className="homepage-container">
            <Tabs>
              <Tab label="Write">
                <WriteTab
                  llmChoice={llmChoice}
                  handleLlmChoiceChange={handleLlmChoiceChange}
                  userSelectedLLM={userSelectedLLM}
                  taskChoice={taskChoice}
                  handleTaskChoiceChange={handleTaskChoiceChange}
                  languageChoice={languageChoice}
                  summaryStyleChoice={summaryStyleChoice}
                  handleSummaryStyleChoiceChange={
                    handleSummaryStyleChoiceChange
                  }
                  handleLanguageChoiceChange={handleLanguageChoiceChange}
                  prompt={prompt}
                  setPrompt={setPrompt}
                  sourceText={sourceText}
                  setSourceText={setSourceText}
                  handleFetchText={handleFetchText}
                  fetchError={fetchError}
                  isFetching={isFetching}
                  onFetchText={handleFetchText}
                  handleGenerateClick={handleGenerateClick}
                  isGenerating={isGenerating}
                  generatedText={generatedText}
                  setGeneratedText={setGeneratedText}
                  scratchPadText={scratchPadText}
                  setScratchPadText={setScratchPadText}
                  draftHistory={draftHistory}
                  pressReleaseLanguage={pressReleaseLanguage}
                  setPressReleaseLanguage={setPressReleaseLanguage}
                  saveToFile
                  currentTask={currentTask}
                  brainstormHistory={brainstormHistory}
                  chatHistory={chatHistory}
                  sessionData={sessionData}
                  setSessionData={setSessionData}
                  updateTokenData={updateTokenData}
                  setCurrentTask={setCurrentTask}
                  setIsNewChatSession={setIsNewChatSession}
                  setBrainstormHistory={setBrainstormHistory}
                  setChatHistory={setChatHistory}
                  setNonChatDraftHistory={setNonChatDraftHistory}
                  setTokenInfo={setTokenInfo}
                  isNewChatSession={isNewChatSession}
                  exchangeRate={exchangeRate}
                  setUsageTask={setUsageTask}
                  updateTaskUsage={updateTaskUsage}
                  messages={writeTabMessages}
                  setMessages={setWriteTabMessages}
                  setDraftHistory={setDraftHistory}
                  isTyping={writeTabIsTyping}
                  setIsTyping={setWriteTabIsTyping}
                  savePptxModalOpen={writeTabSavePptxModalOpen}
                  setSavePptxModalOpen={setWriteTabSavePptxModalOpen}
                  pptxFilename={writeTabPptxFilename}
                  setPptxFilename={setWriteTabPptxFilename}
                  isPptxGenerating={writeTabIsPptxGenerating}
                  setIsPptxGenerating={setWriteTabIsPptxGenerating}
                  onClearChat={clearWriteTabState}
                />
              </Tab>

              <Tab label="Chat with PDF">
                <div className="content-container">
                  <p style={{ fontWeight: "bold" }}>
                    Ask questions about the contents of PDF documents.
                  </p>
                </div>
                <TakeNotesTab
                  documents={documents}
                  setDocuments={setDocuments}
                  selectedDocument={selectedDocument}
                  setSelectedDocument={setSelectedDocument}
                  documentViewerUrls={documentViewerUrls}
                  setDocumentViewerUrls={setDocumentViewerUrls}
                  handleDocumentUpload={handleDocumentUpload}
                  scale={notesScale}
                  setScale={setNotesScale}
                  pageNumber={notesPageNumber}
                  setPageNumber={setNotesPageNumber}
                  numPages={notesNumPages}
                  setNumPages={setNotesNumPages}
                  messages={notesMessages}
                  setMessages={setNotesMessages}
                  isTyping={notesIsTyping}
                  setIsTyping={setNotesIsTyping}
                  error={notesError}
                  setError={setNotesError}
                  isError={notesIsError}
                  setIsError={setNotesIsError}
                  setTokenInfo={setTokenInfo}
                />
              </Tab>

              <Tab label="Summarize PDF">
                <div className="content-container">
                  <p style={{ fontWeight: "bold" }}>Summarize a long PDF.</p>
                </div>
                <UnifiedPdfSummaryTab
                  outputHistory={outputHistory}
                  setOutputHistory={setOutputHistory}
                  fileUrl={fileUrl}
                  setFileUrl={setFileUrl}
                  numPages={numPages}
                  setNumPages={setNumPages}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  onTokenCountUpdate={handleTokenCountUpdate}
                  scale={scale}
                  setScale={setScale}
                  summaryMode={summaryMode}
                  setSummaryMode={setSummaryMode}
                />
              </Tab>

              <Tab label="Create Image">
                <CreateImageTab
                  model={imageModel}
                  setModel={setImageModel}
                  prompt={imagePrompt}
                  setPrompt={setImagePrompt}
                  imageUrl={generatedImageUrl}
                  setImageUrl={setGeneratedImageUrl}
                  upscaleImage={upscaleImage}
                  isGenerating={isGeneratingImage}
                  setIsGenerating={setIsGeneratingImage}
                  enhancePrompt={enhancePrompt}
                  setEnhancePrompt={setEnhancePrompt}
                  uploadedImageUrl={uploadedImageUrl}
                  setUploadedImageUrl={setUploadedImageUrl}
                  transformedImageUrl={transformedImageUrl}
                  setTransformedImageUrl={setTransformedImageUrl}
                  uploadedFile={uploadedFile}
                  setUploadedFile={setUploadedFile}
                  upscaledImageUrl={upscaledImageUrl}
                  setUpscaledImageUrl={setUpscaledImageUrl}
                  task={imageTask}
                  setTask={setImageTask}
                />
              </Tab>
              <Tab label="Transcribe Audio">
                <TranscribeAudioTab
                  task={audioTask}
                  setTask={setAudioTask}
                  language={audioLanguage}
                  setLanguage={setAudioLanguage}
                  file={audioFile}
                  setFile={setAudioFile}
                  audioUrl={audioUrl}
                  setAudioUrl={setAudioUrl}
                  transcriptionResult={transcriptionResult}
                  setTranscriptionResult={setTranscriptionResult}
                  transcriptionResponse={transcriptionResponse}
                  setTranscriptionResponse={setTranscriptionResponse}
                  isLoading={isAudioLoading}
                  setIsLoading={setIsAudioLoading}
                />
              </Tab>
            </Tabs>
          </div>
        )}
      </Box>
    );
  } else {
    // Render only the LoginForm if not authenticated
    return (
      <Box sx={{ flexGrow: 1 }}>
        <LoginForm />
      </Box>
    );
  }
};

export default HomePage;
